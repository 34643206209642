import { template as template_3dc24052923c4b7db58ebec62c894499 } from "@ember/template-compiler";
const FKLabel = template_3dc24052923c4b7db58ebec62c894499(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
