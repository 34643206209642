import { template as template_5662bd818e3f4ef69e567b2b8619458e } from "@ember/template-compiler";
const FKControlMenuContainer = template_5662bd818e3f4ef69e567b2b8619458e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
